<template>
  <div class="bg-white rounded-10 p-10 pb-20 text-left flex">
    <div class="w-1/2 mr-10">
      <div class="text-c16 font-c5 flex">
        <div class="w-2/3">Référence</div>
        <div class="w-1/3 ml-6 text-90">Montant</div>
      </div>

      <div
          v-if="charge"
          class="flex justify-center mt-16"
      >
        <easy-spinner
            type="dots"
            size="50"
        />
      </div>

      <div class="mt-10 text-90 text-center" v-if="allData.length === 0">Aucun transfère pour le moment</div>

      <div class="mt-10" v-for="(item, index) in allData" :key="index">
        <templateMomo :donnes="item" :index="index" @info="retourValue"/>
      </div>
    </div>

    <div class="w-1/2 pl-20 bloc2">
      <!--      <div class="flex items-center justify-end">-->
      <!--        <radio height="22px" width="22px" @info="retourIgnore"/>-->
      <!--        <div class="text-c16 text-60 ml-3">Ignorer cette étape</div>-->
      <!--      </div>-->

      <div class="mt-14">
        <div class="text-c16 text-98">
          Total mobile money
        </div>

        <div class="text-c24 font-c6 mt-2">
          {{ totalAmount.toLocaleString() }} <span class="text-90">FCFA</span>
        </div>
      </div>

      <!--      <textare placeholder="Ecrivez une note..." class="mt-16" radius="5px" height="163px"/>-->
    </div>
  </div>
</template>

<script>
// import radio from '../../helper/form/radio'
// import textare from '../../helper/form/textarea'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import templateMomo from './templateMomoFinal'

export default {
  name: "Index",
  components: {
    // radio,
    // textare,
    templateMomo
  },
  props: {
  },

  data () {
    return {
      allData: [],
      forget: false,
      wallet: null,
      allSelect: [],
      charge: true
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    totalAmount: function () {
      if (this.allSelect.length > 0) {
        let total = 0
        for (let item in this.allSelect) {
          total = total + this.allSelect[item].amount
        }
        return total
      } else {
        return 0
      }
    }
  },

  created () {
    this.wallet = this.$store.getters.trackTemporal
    this.getCheque()
  },

  methods: {
    retourIgnore (answer) {
      this.forget = answer
    },

    retourValue (answer) {
      if (answer.active) {
        this.allSelect.push(this.allData[answer.index])
      } else {
        for (let item in this.allSelect) {
          if (this.allSelect[item].id === this.allData[answer.index].id) {
            this.allSelect.splice(item, 1)
          }
        }
      }
      this.sender()
    },

    sender () {
      if (!this.forget){
        this.$emit('info', this.allSelect)
      }
    },

    getCheque() {
      http.get(apiroutes.baseURL + apiroutes.allMomo + '?id=' + this.wallet.agency.id)
          .then(response => {
            console.log('success')
            console.log(response)
            this.allData = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
.inputo {
  background: #F5F5F5;
  border: 0.956973px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4.78486px;
  height: 36.36px;
}
</style>
